import { useRef, memo, ReactNode, Children, CSSProperties, useMemo } from 'react';
import { CardSlotMemo } from './card-slot';
import { ScrollButtonMemo } from './scroll-button';
import { useCardScroller } from '../../../hooks/use-card-scroller';
import {
  ARROW_NAV_CONTAINER_CLASS,
  ARROW_NAV_CONTAIN_ATTRIBUTE,
  ARROW_NAV_IGNORE_ATTRIBUTE,
} from '../../../utils/nav-system';
import { SCROLL_RESTORATION_TARGET_CLASS } from '../../../constants.mjs';
import styles from './card-scroller.module.css';

export type CardScrollerProps = {
  className?: string;
  scrollerId?: string;
  cardSize?: 'medium' | 'large';
  cardOrientation?: 'landscape' | 'portrait';
  hasPopups?: boolean;
  hideScrollIndicator?: boolean;
  children?: ReactNode;
};

export function CardScroller({
  className = '',
  scrollerId = '',
  cardSize = 'medium',
  cardOrientation = 'landscape',
  hasPopups = false,
  hideScrollIndicator = false,
  children = null,
}: CardScrollerProps) {
  // NB: Empty nodes (null, undefined, and Booleans) will be omitted in the
  // returned array, when using Children.toArray(). Other Children methods
  // will return the children as is, without filtering out empty nodes, hence
  // we want to use Children.toArray() to ensure that we only get valid
  // children.
  const childrenArray = useMemo(() => Children.toArray(children), [children]);
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const scrollContentRef = useRef<HTMLDivElement>(null);
  const scrollIndicatorThumbRef = useRef<HTMLDivElement>(null);
  const {
    canScroll,
    canScrollBack,
    canScrollForward,
    visibleRange,
    scrollToPrevBatch,
    scrollToNextBatch,
  } = useCardScroller({
    scrollElementRef,
    scrollContentRef,
    scrollIndicatorThumbRef,
    childrenCount: childrenArray.length,
    cardSize,
    cardOrientation,
  });

  return (
    <div
      className={`${styles.root} ${styles[`${cardSize}CardSize`]} ${styles[`${cardOrientation}CardOrientation`]} ${hasPopups ? styles.hasPopups : ''} ${className}`}
      style={
        {
          '--card-scroller--item-count': childrenArray.length,
        } as CSSProperties
      }
    >
      <ScrollButtonMemo
        direction="prev"
        canScroll={canScrollBack}
        scrollToNextBatch={scrollToPrevBatch}
      />
      <div
        ref={scrollElementRef}
        id={scrollerId}
        className={`${styles.scroller} ${SCROLL_RESTORATION_TARGET_CLASS} ${ARROW_NAV_CONTAINER_CLASS}`}
        {...{
          [ARROW_NAV_CONTAIN_ATTRIBUTE]: 'x',
          [ARROW_NAV_IGNORE_ATTRIBUTE]: 'y',
        }}
      >
        <div ref={scrollContentRef} className={styles.content}>
          {childrenArray.map((child, index) => {
            return (
              <CardSlotMemo
                key={index}
                isVisible={index >= visibleRange.start && index <= visibleRange.end}
                index={index}
                card={child}
              />
            );
          })}
        </div>
      </div>
      <ScrollButtonMemo
        direction="next"
        canScroll={canScrollForward}
        scrollToNextBatch={scrollToNextBatch}
      />
      {!hideScrollIndicator && (
        <div className={`${styles.scrollIndicator} ${canScroll ? styles.isActive : ''}`}>
          <div ref={scrollIndicatorThumbRef} className={styles.scrollIndicatorThumb}></div>
        </div>
      )}
    </div>
  );
}

export const CardScrollerMemo = memo(CardScroller);
