import { memo, Fragment } from 'react';
import { CardMemo, CardProps } from '../card/card';
import { CardScrollerMemo } from '../card-scroller/card-scroller';
import { CardListHeaderMemo } from '../card-list-header/card-list-header';
import { useIsInView } from '../../../hooks/use-is-in-viewport';
import styles from './card-list.module.css';

export type CardListItemProps = Pick<
  CardProps,
  'type' | 'id' | 'name' | 'launchUrl' | 'imageUrl' | 'videoUrl' | 'shortDescription' | 'categories'
>;

export type CardListProps = {
  id: string;
  items: CardListItemProps[];
  headerTitle: string;
  headerTitleLink?: string;
  headerIcon?: React.ReactNode;
  headerSize?: 'medium' | 'large';
  headerButtonLink?: string;
  headerButtonLinkText?: string;
  cardSize?: 'medium' | 'large';
  cardOrientation?: 'landscape' | 'portrait';
};

type CardListContentProps = Omit<CardListProps, 'id'> & {
  headerId: string;
  scrollerId: string;
};

function CardListContent({
  headerId,
  scrollerId,
  headerTitle,
  headerTitleLink,
  headerIcon,
  headerSize,
  headerButtonLink,
  headerButtonLinkText,
  cardSize,
  cardOrientation,
  items,
}: CardListContentProps) {
  return (
    <Fragment>
      <div className={styles.scrollToTarget}></div>
      <CardListHeaderMemo
        id={headerId}
        className={styles.header}
        title={headerTitle}
        titleLink={headerTitleLink}
        buttonLink={headerButtonLink}
        buttonLinkText={headerButtonLinkText}
        icon={headerIcon}
        size={headerSize}
      />
      <CardScrollerMemo
        className={styles.scroller}
        scrollerId={scrollerId}
        cardSize={cardSize}
        cardOrientation={cardOrientation}
        hasPopups={true}
      >
        {items.map((item, index) => (
          <CardMemo
            key={index}
            type={item.type}
            id={item.id}
            name={item.name}
            launchUrl={item.launchUrl}
            imageUrl={item.imageUrl}
            videoUrl={item.videoUrl}
            shortDescription={item.shortDescription}
            categories={item.categories}
            size={cardSize}
            orientation={cardOrientation}
          />
        ))}
      </CardScrollerMemo>
    </Fragment>
  );
}

const CardListContentMemo = memo(CardListContent);

export function CardList({
  id,
  items,
  headerTitle,
  headerTitleLink,
  headerIcon,
  headerSize,
  headerButtonLink,
  headerButtonLinkText,
  cardSize = 'medium',
  cardOrientation = 'landscape',
}: CardListProps) {
  const rootId = `card-list-${id}`;
  const headerId = `card-list-header-${id}`;
  const scrollerId = `card-list-scroller-${id}`;
  const [containerRef, isNearViewport] = useIsInView({
    root: '#__next',
    rootMargin: '500px 0px 500px 0px',
    initialInView: true,
    skip: !items.length,
  });

  if (!items.length) return null;

  return (
    <div
      ref={containerRef}
      id={rootId}
      className={`${styles.root} ${styles[`${cardSize}CardSize`]} ${styles[`${cardOrientation}CardOrientation`]} ${styles[`${headerSize}HeaderSize`]} ${isNearViewport ? styles.isNearViewport : ''}`}
      role="region"
      aria-labelledby={headerId}
    >
      <CardListContentMemo
        headerId={headerId}
        scrollerId={scrollerId}
        headerTitle={headerTitle}
        headerTitleLink={headerTitleLink}
        headerButtonLink={headerButtonLink}
        headerButtonLinkText={headerButtonLinkText}
        headerIcon={headerIcon}
        headerSize={headerSize}
        cardSize={cardSize}
        cardOrientation={cardOrientation}
        items={items}
      />
    </div>
  );
}

export const CardListMemo = memo(CardList);
